/*label {
  position: relative;
}

label:before {
  background: #000;
  content: "";
  height: 1px;
  position: absolute;
  top: 0.8em;
  transition: width 0.3s ease;
  width: 0%;
}

[type="checkbox"]:checked + label:before {
  width: 100%;
}*/

h1 {
  font-family: MerchantFont;
}

span {
  font-family: MerchantFont;
}

.strikethrough {
  text-decoration: line-through;
}
