.splash-screen {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

span {
  font-family: Merchant;
}

.elementToFadeInAndOut {
  opacity: 1;
  animation: fadeinout 3s linear;
  animation-fill-mode: forwards;
}

@-webkit-keyframes fadeinout {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeinout {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
