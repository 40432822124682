.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.parchment {
  background-image: url("./images/parchment.png");
}

.parchmentClosed {
  background-image: url("./images/parchmentClosed.png");
}

.parchmentClosedStreamer {
  background-image: url("./images/parchmentClosed_Streamer.png");
}

.parchmentStreamer {
  background-image: url("./images/parchment6.png");
}

.backStreamer {
  background: #00ff00;
}

.back {
  background: #000000;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

::-webkit-scrollbar {
  display: none;
}

@font-face {
  font-family: MerchantFont;
  src: url(./fonts/ManuskriptAntD-Reg.otf);
}

.noScrolling {
  overflow: hidden;
  height: 100%;
}

.noScrollingX {
  overflow-x: hidden;
  position: relative;
  overscroll-behavior-y: contain;
}

.elementFadeOut {
  opacity: 1;
  animation: fadeout 1s linear;
  animation-fill-mode: forwards;
}

@-webkit-keyframes fadeout {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeout {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.elementFadeIn {
  opacity: 1;
  animation: fadein 1s linear;
  animation-fill-mode: forwards;
}

.flash {
  animation: flash 1s linear;
  animation-fill-mode: forwards;
}

@-webkit-keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes flash {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.height-100 {
  height: 100%;
}

[type="checkbox"] {
  display: none;
}

label.check {
  position: relative;
  margin-left: 2em;
}

label:before,
label:after {
  display: inline-block;
  position: absolute;
  top: 0;
  left: -1.5em;
}

label:before {
  content: "[\0A0\0A0]";
}

label:after {
  content: "x";
  display: none;
  width: 1.1em;
  text-align: center;
}

label span.strike:before {
  background: #000;
  content: "";
  height: 1px;
  position: absolute;
  top: 0.9em;
  transition: width 0.3s ease;
  width: 0%;
}

[type="checkbox"]:checked + label:after {
  display: block;
}

[type="checkbox"]:checked + label span.strike:before {
  width: 100%;
}

.popX {
  position: absolute;
  color: darkred;
  font-size: 40px;
  pointer-events: none;
  margin-left: 50px;
  margin-top: -25px;
}

.plus-one {
  position: absolute;
  font-size: 40px;
  pointer-events: none;
  margin-left: 50px;
  margin-top: -25px;
}

.plus-sunk {
  position: absolute;
  font-size: 40px;
  pointer-events: none;
  margin-left: 25px;
  margin-top: -50px;
}

/* CSS */
.button-55 {
  align-self: center;
  background-color: transparent;
  background-image: none;
  background-position: 0 90%;
  background-repeat: repeat no-repeat;
  background-size: 4px 3px;
  border: 3px solid black;
  border-radius: 15px 225px 255px 15px 15px 255px 225px 15px;
  border-style: solid;
  box-shadow: rgba(0, 0, 0, 0.2) 15px 28px 25px -18px;
  box-sizing: border-box;
  color: black;
  cursor: pointer;
  display: inline-block;
  font-family: MerchantFont;
  /*font-size: 1rem;*/
  line-height: 23px;
  outline: none;
  /*padding: 0.5rem;*/
  text-decoration: none;
  transition: all 235ms ease-in-out;
  border-bottom-left-radius: 15px 255px;
  border-bottom-right-radius: 225px 15px;
  border-top-left-radius: 255px 15px;
  border-top-right-radius: 15px 225px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  margin-left: 5px;
  margin-right: 5px;
}

.button-55:hover {
  color: inherit;
}

.merchant {
  font-family: MerchantFont;
}
